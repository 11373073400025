import React, {FormEvent, ReactElement, useState} from 'react';
import {IRecipeProps} from './RecipeForm';
import {Form, Col, ListGroup, InputGroup} from 'react-bootstrap';
import {TStuckOnAny} from '../helpers/utils';

export interface IRecipeSciencedProps {
    recipe: IRecipeProps;
}

export default function RecipeScienced(p: IRecipeSciencedProps): ReactElement {
    const [baseWeight, setBaseWeight] = useState('') as TStuckOnAny;
    const cw = '100px';

    const calculate = (base: number, percent: number): number =>
        Math.round(base * ((percent || 0) / 100) * 10) / 10;

    const handleValueChange = (e: FormEvent): void => {
        const t = e.target as HTMLInputElement;

        setBaseWeight(t.value);
    };

    return (
        <>
            {p.recipe.description ? (
                <p className="mb-4">props.recipe.description</p>
            ) : (
                <p className="mb-4">
                    <em>No description provided.</em>
                </p>
            )}
            <hr className="mb-4" />
            {p.recipe.base_ingredient && (
                <>
                    <h6 className="mb-3">Ingredients</h6>
                    <ListGroup className="input-list-group mb-4">
                        <ListGroup.Item key="ing-base">
                            <Form.Row className="align-items-center">
                                <Col style={{flex: `0 0 ${cw}`}}>
                                    <InputGroup>
                                        <Form.Control
                                            aria-label="Weight"
                                            aria-describedby="_base-weight"
                                            type="number"
                                            id="base-weight"
                                            name="base-weight"
                                            placeholder="0"
                                            value={baseWeight}
                                            onChange={handleValueChange}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text id="_base-weight">
                                                g
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <p>{p.recipe.base_ingredient.name}</p>
                                </Col>
                            </Form.Row>
                        </ListGroup.Item>
                        {p.recipe.ingredients && p.recipe.ingredients.length
                            ? p.recipe.ingredients.map((ing, idx) => (
                                <ListGroup.Item key={`ing-${idx}`}>
                                    <Form.Row className="align-items-center">
                                        <Col style={{flex: `0 0 ${cw}`}}>
                                            <InputGroup>
                                                <Form.Control
                                                    aria-label="Weight"
                                                    aria-describedby={`_ing-${idx}-weight`}
                                                    type="number"
                                                    id={`ing-${idx}-weight`}
                                                    name={`ing-${idx}-weight`}
                                                    placeholder="0"
                                                    value={calculate(
                                                        baseWeight,
                                                        parseFloat(
                                                            ing.percentage
                                                                  || '0'
                                                        )
                                                    )}
                                                    disabled
                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text
                                                        id={`_ing-${idx}-weight`}
                                                    >
                                                          g
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <p>{ing.ingredient.name}</p>
                                        </Col>
                                    </Form.Row>
                                </ListGroup.Item>
                            ))
                            : ''}
                    </ListGroup>
                </>
            )}
            {p.recipe.instructions && p.recipe.instructions.length ? (
                <>
                    <h6 className="mb-3">Instructions</h6>
                    <ListGroup className="input-list-group mb-4">
                        {p.recipe.instructions.map((ins, idx) => (
                            <ListGroup.Item key={`ins-${idx}`}>
                                {ins}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </>
            ) : (
                ''
            )}
        </>
    );
}
