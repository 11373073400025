import {Button, Modal} from 'react-bootstrap';
import React, {ReactElement, MouseEventHandler, useState} from 'react';

import {TStuckOnAny} from '../helpers/utils';

export interface IMessageModalProps {
    body: string | JSX.Element | JSX.Element[];
    show: boolean;
    type?: 'error' | 'notice' | 'cancelable';
    agreeLabel?: string;
    cancelLabel?: string;
    heading?: string;
    onHide?: MouseEventHandler<TStuckOnAny>;
    onAgree?: MouseEventHandler<TStuckOnAny>;
    onExited?: Function;
}

export function MessageModal(props: IMessageModalProps): ReactElement {
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleButtonClick = (e: TStuckOnAny, action: TStuckOnAny): void => {
        setButtonDisabled(true);

        setTimeout(() => action(e), 200);
    };

    const handleHide = (e: TStuckOnAny): void => {
        handleButtonClick(e, props.onHide);
    };

    const handleAgree = (e: TStuckOnAny): void => {
        handleButtonClick(e, props.onAgree);
    };

    const handleExited = (): void => {
        setButtonDisabled(false);

        if(props.onExited) props.onExited();
    };

    const defaultHeading = (): string => {
        switch(props.type) {
            case 'error':
                return 'Oopsie';
            case 'cancelable':
                return 'Please Read';
            default:
                return 'Notice';
        }
    };

    return (
        <Modal show={props.show} onHide={props.onHide} onExited={handleExited}>
            <Modal.Header translate="true" closeButton>
                <Modal.Title>{props.heading || defaultHeading()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
                {props.type === 'cancelable' ? (
                    <>
                        <Button
                            variant="secondary"
                            disabled={buttonDisabled}
                            onClick={handleHide}
                        >
                            {props.cancelLabel}
                        </Button>
                        <Button
                            variant="primary"
                            disabled={buttonDisabled}
                            onClick={handleAgree}
                        >
                            {props.agreeLabel}
                        </Button>
                    </>
                ) : (
                    <Button
                        variant="primary"
                        disabled={buttonDisabled}
                        onClick={handleHide}
                    >
                        {props.agreeLabel}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

MessageModal.defaultProps = {
    type: 'notice',
    cancelLabel: 'Cancel',
    agreeLabel: 'Okay',
};
