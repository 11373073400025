import React, {ReactElement} from 'react';

import PageHeader from '../components/PageHeader';
import IngredientsList from '../components/IngredientsList';
import {Helmet} from 'react-helmet';

export default function Ingredients(): ReactElement {
    return (
        <>
            <Helmet>
                <title>Ingredients</title>
            </Helmet>
            <PageHeader>Ingredients</PageHeader>
            <IngredientsList />
        </>
    );
}
