import {
    SortableElement as element,
    SortableContainer as container,
    SortableHandle as handle,
} from 'react-sortable-hoc';

import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import React, {ReactElement, Dispatch, FormEvent} from 'react';
import {ListGroup, Form, Col, Button, Row} from 'react-bootstrap';
import {GoX, GoThreeBars, GoPlus} from 'react-icons/go';

export interface ISortableInstructionsProps {
    items: string[];
    setItems: Dispatch<React.SetStateAction<string[]>>;
    deleteButtonDisabled: boolean;
    readonly?: boolean;
}

const SortableItem = element(({children}: {children: ReactElement}) => (
    <>{children}</>
));

const SortableList = container(({children}: {children: ReactElement}) => (
    <>{children}</>
));

const DragHandle = handle(() => (
    <Button as="div" variant="dark">
        <GoThreeBars />
        <span className="sr-only">Drag to Sort</span>
    </Button>
));

export default function SortableInstructions(
    props: ISortableInstructionsProps
): ReactElement {
    const addItem = (): void => {
        if(props.readonly) return;

        props.setItems(['', ...props.items]);
    };

    const instructionFormTemplate = (idx: number): JSX.Element => (
        <ListGroup.Item>
            <Form.Row>
                <Col>
                    <Form.Label srOnly htmlFor={`instructions[${idx}]`}>
                        Instruction {idx + 1}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        maxLength={300}
                        id={`instructions[${idx}]`}
                        name={`instructions[${idx}]`}
                        placeholder="Write something..."
                        value={props.items[idx]}
                        onChange={(e: FormEvent): void => {
                            const t = e.target as HTMLInputElement;
                            const newState = [...props.items];

                            newState[idx] = t.value;

                            props.setItems(newState);
                        }}
                    />
                </Col>
                {!props.readonly && (
                    <>
                        <Col xs="auto">
                            <DragHandle />
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant="danger"
                                onClick={(e: FormEvent): void => {
                                    e.preventDefault();

                                    props.setItems((prevState) =>
                                        prevState.filter(
                                            (_prev, i) => i !== idx
                                        ));
                                }}
                                disabled={props.deleteButtonDisabled}
                            >
                                <GoX />
                                <span className="sr-only">Delete</span>
                            </Button>
                        </Col>
                    </>
                )}
            </Form.Row>
        </ListGroup.Item>
    );

    instructionFormTemplate.propTypes = {
        items: PropTypes.arrayOf(PropTypes.string),
        setItems: PropTypes.func,
        deleteButtonDisabled: PropTypes.bool,
        readonly: PropTypes.bool,
    };

    return (
        <SortableList
            lockAxis="y"
            useDragHandle={true}
            onSortEnd={({
                oldIndex,
                newIndex,
            }: {
                oldIndex: number;
                newIndex: number;
            }): void => {
                const newItems = arrayMove(props.items, oldIndex, newIndex);

                if(oldIndex !== newIndex) props.setItems(newItems);
            }}
        >
            <ListGroup className="input-list-group mb-4">
                <ListGroup.Item
                    className={`iconed${props.readonly ? '' : ' c-ptr'}`}
                    onClick={addItem}
                    active
                >
                    <Row>
                        <Col>Instructions</Col>
                        {!props.readonly && (
                            <Col xs="auto">
                                <GoPlus />
                            </Col>
                        )}
                    </Row>
                </ListGroup.Item>
                {props.items.map((_item, idx) => (
                    <SortableItem key={`instruction_${idx}`} index={idx}>
                        {instructionFormTemplate(idx)}
                    </SortableItem>
                ))}
            </ListGroup>
        </SortableList>
    );
}
