import React, {ReactElement, useEffect, useState} from 'react';
import {useStoreValue} from 'react-context-hook';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {Container, Navbar, Nav} from 'react-bootstrap';

import {env} from '../helpers/meta';

export default function Header(): ReactElement {
    const location = useLocation();
    const [path, setPath] = useState(window.location.pathname);

    const logoutUrl = `${env.apiURI}/logout`;
    const logoutLink = encodeURI(`${logoutUrl}?from=${path}&loggedout=true`);

    const isLoggedIn = useStoreValue('isLoggedIn');
    const user = useStoreValue('user');

    useEffect(() => {
        setPath(window.location.pathname);
    }, [location]);

    return (
        <Navbar
            className="app-header"
            bg="dark"
            variant="dark"
            collapseOnSelect
            expand="md"
        >
            <Container>
                <Nav className="branding">
                    <Nav.Link
                        eventKey="brand"
                        as={Link}
                        to="/"
                        className="active"
                    >
                        {env.appName}
                    </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-end"
                >
                    <Nav
                        className="main-menu justify-content-end"
                        activeKey={false}
                    >
                        {isLoggedIn ? (
                            <>
                                <Nav.Link
                                    eventKey="home"
                                    as={NavLink}
                                    to="/"
                                    exact
                                >
                                    Hi, {user.first_name || user.username}!
                                </Nav.Link>
                                <Nav.Link
                                    eventKey="recipes"
                                    as={NavLink}
                                    to="/recipes"
                                >
                                    Recipes
                                </Nav.Link>
                                <Nav.Link
                                    eventKey="ingredients"
                                    as={NavLink}
                                    to="/ingredients"
                                >
                                    Ingredients
                                </Nav.Link>
                                <Nav.Link eventKey="logout" href={logoutLink}>
                                    Logout
                                </Nav.Link>
                            </>
                        ) : (
                            <>
                                <Nav.Link
                                    eventKey="login"
                                    as={NavLink}
                                    to="/login"
                                >
                                    Login
                                </Nav.Link>
                                <Nav.Link
                                    eventKey="register"
                                    as={NavLink}
                                    to="/register"
                                >
                                    Register
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
