import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    FormEvent,
} from 'react';

import {Redirect} from 'react-router-dom';
import {Form, Button, ListGroup} from 'react-bootstrap';
import {AxiosResponse, AxiosError} from 'axios';

import IngredientSelect, {ingredientSeed} from './IngredientSelect';
import {IIngredientProps} from './IngredientForm';
import {processErrors} from '../helpers/utils';
import {addOrEditRecipe, addIngredient} from '../helpers/api';
import {MessageModal} from './MessageModal';
import SortableInstructions from './SortableInstructions';
import SortableIngredients from './SortableIngredients';

export interface IRecipeFormOnSaveFunction {
    (isNew: boolean, savedRecipe: IRecipeProps): unknown;
}

export interface IRecipeFormOnDeleteFunction {
    (deletedRecipeId?: IRecipeProps['_id']): unknown;
}

export interface IRecipeIngredientProps {
    ingredient: IIngredientProps;
    percentage?: string;
}

export interface IRecipeProps {
    _id?: string;
    name?: string;
    description?: string;
    base_ingredient?: IIngredientProps;
    ingredients?: IRecipeIngredientProps[];
    instructions?: string[];
    onSave?: IRecipeFormOnSaveFunction;
    onDelete?: IRecipeFormOnDeleteFunction;
}

export interface IRecipeFormProps extends IRecipeProps {
    isNew?: boolean;
    readonly?: boolean;
}

export interface IRecipeOutboundProps {
    _id: string;
    name: string;
    description?: string;
    base_ingredient: string;
    ingredients?: {
        ingredient: string;
        percentage?: string;
    }[];
    instructions?: string[];
}

export default function RecipeForm(props: IRecipeFormProps): ReactElement {
    const [fields, setFields] = useState({
        _id: props._id || '',
        name: props.name || '',
        description: props.description || '',
    });

    const [baseIngredient, setBaseIngredient] = useState(
        props.base_ingredient || ({} as IIngredientProps)
    );

    const [ingredients, setIngredients] = useState(
        props.ingredients || ([] as IRecipeIngredientProps[])
    );

    const [instructions, setInstructions] = useState(
        props.instructions || ([] as string[])
    );

    const [errors, setErrors] = useState([] as JSX.Element[]);
    const [showErrors, setShowErrors] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [savedRecipe, setSavedRecipe] = useState({} as IRecipeProps);
    const [savedRecipeIsNew, setSavedRecipeIsNew] = useState(false);

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();

        setButtonDisabled(true);

        const outbound = {...fields} as IRecipeOutboundProps;

        if(baseIngredient._id && !baseIngredient._id.startsWith('___')) {
            outbound.base_ingredient = baseIngredient._id;
        }

        if(ingredients.length) {
            const preparedIngredients = ingredients
                .filter((i) => !i.ingredient._id.startsWith('___'))
                .map((i) => ({
                    ingredient: i.ingredient._id,
                    percentage: i.percentage,
                }));

            if(preparedIngredients.length) {
                outbound.ingredients = preparedIngredients;
            }
        }

        if(instructions.length) {
            const preparedInstructions = instructions.filter((i) => i.length);

            if(preparedInstructions.length) {
                outbound.instructions = preparedInstructions;
            }
        }

        const isNew = outbound._id.startsWith('___');

        if(isNew) outbound._id = '';

        addOrEditRecipe(outbound, {
            onError: (err: AxiosError) => {
                setErrors(processErrors(err));
                setShowErrors(true);
            },
            onSuccess: (responseData: AxiosResponse['data']) => {
                setSavedRecipeIsNew(isNew);
                setSavedRecipe(responseData.details);

                if(props.onSave) props.onSave(isNew, responseData.details);
            },
            onFinally: () => {
                if(isMounted.current) setButtonDisabled(false);
            },
        });
    };

    const handleValueChange = (e: FormEvent): void => {
        const t = e.target as HTMLInputElement;
        const value = t.type === 'checkbox' ? t.checked : t.value;
        const updatedFields = {...fields, [t.name]: value};

        setFields(updatedFields);
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            {typeof savedRecipe._id !== 'undefined' && (
                <Redirect
                    to={{
                        pathname: `/recipes/${savedRecipe._id}`,
                        state: {isNew: savedRecipeIsNew, savedRecipe},
                    }}
                />
            )}
            {
                <MessageModal
                    type="error"
                    show={showErrors}
                    onHide={(): void => setShowErrors(false)}
                    onExited={(): void => setErrors([])}
                    body={errors}
                />
            }
            <fieldset disabled={props.readonly}>
                <ListGroup className="input-list-group mb-4">
                    <ListGroup.Item active>Basics</ListGroup.Item>
                    <ListGroup.Item>
                        <Form.Group>
                            <Form.Label srOnly htmlFor="name">
                                Name
                            </Form.Label>
                            <Form.Control
                                id="name"
                                name="name"
                                placeholder="Name"
                                onChange={handleValueChange}
                                value={fields.name}
                            />
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Form.Group>
                            <Form.Label srOnly htmlFor="description">
                                Description
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                maxLength={300}
                                id="description"
                                name="description"
                                placeholder="Description"
                                onChange={handleValueChange}
                                value={fields.description}
                            />
                        </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Form.Group>
                            <Form.Label srOnly htmlFor="base_ingredient">
                                Base Ingredient
                            </Form.Label>
                            <IngredientSelect
                                id="base_ingredient"
                                placeholder="Select base ingredient..."
                                options={[baseIngredient]}
                                defaultInputValue={
                                    baseIngredient.name || ingredientSeed().name
                                }
                                onChange={(selected): void => {
                                    if(
                                        selected[0]
                                        && selected[0].customOption
                                    ) {
                                        addIngredient(selected[0], {
                                            onSuccess: (
                                                data: AxiosResponse['data']
                                            ) => {
                                                setBaseIngredient(data.details);
                                            },
                                        });
                                    } else {
                                        setBaseIngredient(
                                            selected[0] || ingredientSeed()
                                        );
                                    }
                                }}
                            />
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
                <SortableIngredients
                    items={ingredients}
                    setItems={setIngredients}
                    deleteButtonDisabled={buttonDisabled}
                    readonly={props.readonly}
                />
                <SortableInstructions
                    items={instructions}
                    setItems={setInstructions}
                    deleteButtonDisabled={buttonDisabled}
                    readonly={props.readonly}
                />
                {!props.readonly && (
                    <Button
                        className="mt-5"
                        type="submit"
                        block
                        disabled={buttonDisabled}
                    >
                        Save Recipe
                    </Button>
                )}
                <Form.Control
                    type="hidden"
                    id="_id"
                    name="_id"
                    value={fields._id}
                    onChange={handleValueChange}
                />
            </fieldset>
        </Form>
    );
}

RecipeForm.defaultProps = {
    _id: '___',
};
