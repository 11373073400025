import React, {ReactElement} from 'react';

import PageHeader from '../components/PageHeader';

export default function Home(): ReactElement {
    return (
        <>
            <PageHeader>Home</PageHeader>
        </>
    );
}
