import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {store} from 'react-context-hook';

import {env} from './meta';
import {IIngredientProps} from '../components/IngredientForm';
import {IRecipeProps, IRecipeOutboundProps} from '../components/RecipeForm';
import {assembleQuery} from 'joots';

const _api = axios.create({
    baseURL: `${env.apiURI}`,
    timeout: 5000,
    withCredentials: true,
});

const errHandler = (err: AxiosError, callback?: Function): 0 | void => {
    if(err.response) {
        if(err.response.data.code === 'notloggedin') {
            store.set('isLoggenIn', false);
            store.set('user', {});
        }
    }

    if(callback) return callback(err);

    return 0;
};

export interface IApiError {
    status: number;
    name: string;
    code: string;
    message: string;
}

export interface IApiValidationError extends IApiError {
    errors?: [
        {
            param: string;
            value: string;
            message: string;
        }
    ];
}

export interface IApiParams {
    config?: AxiosRequestConfig;
    onError?: (err: AxiosError) => void;
    onSuccess?: Function;
    onFinally?: Function;
}

export const checkAuth = ({
    config,
    onError,
    onSuccess,
    onFinally,
}: IApiParams): void => {
    _api.get('/isloggedin', config)
        .then((response: AxiosResponse) => {
            store.set('user', response.data);
            store.set('isLoggedIn', true);

            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            store.set('checkingAuth', false);

            if(onFinally) onFinally();
        });
};

export const register = (
    data: unknown,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.post('/users', data, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const login = (
    data: unknown,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.post('/login', data, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export type TValidGetQueries = {
    limit?: string;
    filter?: string;
    last_result?: string;
};

export interface IIngredientsAxiosResponse extends AxiosResponse {
    data: {
        count: number;
        results: IIngredientProps[];
    };
}

export const getIngredients = (
    {config, onError, onSuccess, onFinally}: IApiParams,
    __queries: TValidGetQueries = {}
): void => {
    if(!__queries.limit || !Number.isInteger(parseInt(__queries.limit))) {
        __queries.limit = '10';
    }

    const assembledQuery = assembleQuery(__queries);

    _api.get(`/self/ingredients${assembledQuery}`, config)
        .then((response: IIngredientsAxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const addIngredient = (
    data: IIngredientProps,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.post('/self/ingredients', data, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const editIngredient = (
    data: IIngredientProps,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.patch(`/self/ingredients/${data._id}`, data, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const addOrEditIngredient = (
    data: IIngredientProps,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    if(data._id && data._id.length) {
        editIngredient(data, {config, onError, onSuccess, onFinally});
    } else {
        addIngredient(data, {config, onError, onSuccess, onFinally});
    }
};

export const deleteIngredient = (
    ingredient_id: IIngredientProps['_id'],
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.delete(`/self/ingredients/${ingredient_id}`, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export interface IRecipeAxiosResponse extends AxiosResponse {
    data: IRecipeProps;
}

export const getRecipe = (
    recipe_id: IRecipeProps['_id'],
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.get(`/self/recipes/${recipe_id}`, config)
        .then((response: IRecipeAxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export interface IRecipesAxiosResponse extends AxiosResponse {
    data: {
        count: number;
        results: IRecipeProps[];
    };
}

export const getRecipes = (
    {config, onError, onSuccess, onFinally}: IApiParams,
    __queries: TValidGetQueries = {}
): void => {
    if(!__queries.limit || !Number.isInteger(parseInt(__queries.limit))) {
        __queries.limit = '10';
    }

    const assembledQuery = assembleQuery(__queries);

    _api.get(`/self/recipes${assembledQuery}`, config)
        .then((response: IRecipesAxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const addRecipe = (
    data: IRecipeOutboundProps,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.post('/self/recipes', data, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const editRecipe = (
    data: IRecipeOutboundProps,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.patch(`/self/recipes/${data._id}`, data, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};

export const addOrEditRecipe = (
    data: IRecipeOutboundProps,
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    if(data._id && data._id.length) {
        editRecipe(data, {config, onError, onSuccess, onFinally});
    } else {
        addRecipe(data, {config, onError, onSuccess, onFinally});
    }
};

export const deleteRecipe = (
    recipe_id: IRecipeProps['_id'],
    {config, onError, onSuccess, onFinally}: IApiParams
): void => {
    _api.delete(`/self/recipes/${recipe_id}`, config)
        .then((response: AxiosResponse) => {
            if(onSuccess) onSuccess(response.data);
        })
        .catch((err: AxiosError) => errHandler(err, onError))
        .finally(() => {
            if(onFinally) onFinally();
        });
};
