import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    FormEvent,
} from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError, AxiosResponse} from 'axios';
import {Form, Col, InputGroup, Button} from 'react-bootstrap';
import {FaRegTrashAlt, FaCheck} from 'react-icons/fa';

import {addOrEditIngredient, deleteIngredient} from '../helpers/api';
import {processErrors} from '../helpers/utils';
import {MessageModal} from './MessageModal';

export interface IIngredientFormOnSaveFunction {
    (isNew?: boolean, savedIngredient?: IIngredientProps): unknown;
}

export interface IIngredientFormOnDeleteFunction {
    (deletedIngredientId?: IIngredientProps['_id']): unknown;
}

export interface IIngredientProps {
    _id: string;
    name: string;
    cost_per_gram?: string;
    onSave?: IIngredientFormOnSaveFunction;
    onDelete?: IIngredientFormOnDeleteFunction;
}

export default function IngredientForm(props: IIngredientProps): ReactElement {
    const [fields, setFields] = useState({
        _id: props._id || '',
        name: props.name || '',
        cost_per_gram: props.cost_per_gram || '',
    });

    const [errors, setErrors] = useState([] as JSX.Element[]);
    const [showErrors, setShowErrors] = useState(false);
    const [fauxProps, setFauxProps] = useState(props);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [fieldsEqProps, setFieldsEqProps] = useState(true);

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const handleValueChange = (e: FormEvent): void => {
        const t = e.target as HTMLInputElement;
        const value = t.type === 'checkbox' ? t.checked : t.value;
        const updatedFields = {...fields, [t.name]: value};

        setFields(updatedFields);

        setFieldsEqProps(
            updatedFields.name === fauxProps.name
                // eslint-disable-next-line eqeqeq
                && updatedFields.cost_per_gram == fauxProps.cost_per_gram
        );
    };

    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();

        setButtonDisabled(true);

        const fieldsCopy = {...fields};
        const isNew = fieldsCopy._id.startsWith('___');

        if(isNew) fieldsCopy._id = '';

        addOrEditIngredient(fieldsCopy, {
            onError: (err: AxiosError) => {
                setErrors(processErrors(err));
                setShowErrors(true);
            },
            onSuccess: (responseData: AxiosResponse['data']) => {
                const newFields = {
                    ...fieldsCopy,
                    _id: responseData.details._id,
                };

                setFields(newFields);
                setFauxProps(newFields);
                setFieldsEqProps(true);

                if(props.onSave) props.onSave(isNew, newFields);
            },
            onFinally: () => {
                if(isMounted.current) setButtonDisabled(false);
            },
        });
    };

    const handleDelete = (e: FormEvent): void => {
        e.preventDefault();

        setButtonDisabled(true);

        if(!fields._id.startsWith('___')) {
            deleteIngredient(fields._id, {
                onError: (err: AxiosError) => {
                    setErrors(processErrors(err));
                    setShowErrors(true);
                },
                onSuccess: () => {
                    if(props.onDelete) props.onDelete(fields._id);
                },
                onFinally: () => {
                    if(isMounted.current) setButtonDisabled(false);
                },
            });
        } else if(props.onDelete) props.onDelete(fields._id);
    };

    return (
        <>
            {
                <MessageModal
                    type="error"
                    show={showErrors}
                    onHide={(): void => setShowErrors(false)}
                    onExited={(): void => setErrors([])}
                    body={errors}
                />
            }
            <Form
                className="ingredient-form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Form.Row>
                    <Col xs={12} md className="mb-frg mb-md-0">
                        <Form.Label htmlFor="name" className="sr-only">
                            Filter
                        </Form.Label>
                        <Form.Control
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={fields.name}
                            onChange={handleValueChange}
                        />
                    </Col>
                    <Col xs md={4} lg={3}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="_cost_per_gram">
                                    &#8369;
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                aria-label="Cost Per Gram"
                                aria-describedby="_cost_per_gram"
                                type="number"
                                min={0}
                                id="cost_per_gram"
                                name="cost_per_gram"
                                placeholder="Cost Per Gram"
                                value={fields.cost_per_gram}
                                onChange={handleValueChange}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        <Button
                            variant="success"
                            type="submit"
                            disabled={buttonDisabled || fieldsEqProps}
                        >
                            <FaCheck />
                            <span className="sr-only">Save</span>
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button
                            variant="danger"
                            onClick={handleDelete}
                            disabled={buttonDisabled}
                        >
                            <FaRegTrashAlt />
                            <span className="sr-only">Delete</span>
                        </Button>
                    </Col>
                    <Form.Control
                        type="hidden"
                        id="_id"
                        name="_id"
                        value={fields._id}
                        onChange={handleValueChange}
                    />
                </Form.Row>
            </Form>
        </>
    );
}

IngredientForm.defaultProps = {
    _id: '',
    name: '',
    cost_per_gram: '',
};
