import React, {useEffect, useState, ReactElement} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {withStore} from 'react-context-hook';
import {Container} from 'react-bootstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './scss/styles.scss';

import {env} from './helpers/meta';
import {checkAuth} from './helpers/api';

import Header from './components/Header';
import Footer from './components/Footer';
import LoadingOverlay from './components/LoadingOverlay';

import PrivateRoute from './routes/PrivateRoute';
import Home from './routes/Home';
import NotFound from './routes/NotFound';
import Recipes from './routes/Recipes';
import Ingredients from './routes/Ingredients';
import RecipeView from './routes/RecipeView';
import RecipeAdd from './routes/RecipeAdd';

const App = (): ReactElement => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkAuth({
            onFinally: () => {
                setTimeout(() => setLoading(false), 300);
            },
        });
    }, []);

    return (
        <BrowserRouter basename={env.appURL || '/'}>
            <LoadingOverlay show={loading} />
            <Helmet
                defaultTitle={env.appName}
                titleTemplate={`%s - ${env.appName}`}
            />
            <Header />
            <div className="app-primary mt-4 mb-5">
                <Container>
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute
                            exact
                            path="/recipes"
                            component={Recipes}
                        />
                        <PrivateRoute
                            exact
                            path="/recipes/add"
                            component={RecipeAdd}
                        />
                        <PrivateRoute
                            exact
                            path="/recipes/:id"
                            component={RecipeView}
                        />
                        <PrivateRoute
                            exact
                            path="/ingredients"
                            component={Ingredients}
                        />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Container>
            </div>
            <Footer />
        </BrowserRouter>
    );
};

export interface IAppState {
    user: Record<string, any>;
    isLoggedIn: boolean;
    checkingAuth: boolean;
}

const initialAppState: IAppState = {
    user: {},
    isLoggedIn: false,
    checkingAuth: true,
};

export default withStore(App, initialAppState);
