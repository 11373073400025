import React, {ReactElement} from 'react';
import {Route, RouteProps} from 'react-router';
import {useStoreValue} from 'react-context-hook';

import RedirectExternal from './RedirectExternal';
import {env} from '../helpers/meta';

type IPrivateRouteProps = RouteProps;

export default function PrivateRoute(
    props: IPrivateRouteProps
): ReactElement | null {
    const isLoggedIn = useStoreValue('isLoggedIn');
    const checkingAuth = useStoreValue('checkingAuth');

    if(!checkingAuth) {
        const path = window.location.pathname;

        if(isLoggedIn) {
            return <Route {...props} />;
        }

        return (
            <Route
                path={path}
                render={(): ReactElement => (
                    <RedirectExternal
                        to={encodeURI(`${env.apiURI}/auth?from=${path}&needlogin=yes`)}
                    />
                )}
            />
        );
    }

    return null;
}
