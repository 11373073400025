import React, {ReactElement, useState} from 'react';
import {Spinner} from 'react-bootstrap';

export interface ILoadingOverlayProps {
    show: boolean;
}

export default function LoadingOverlay(
    props: ILoadingOverlayProps
): ReactElement {
    const [done, setDone] = useState(false);

    const handleTransitionEnd = (): void => {
        setTimeout(() => setDone(true), 500);
    };

    return (
        <>
            {!done && (
                <div
                    className={`loading-screen ${props.show ? 's' : 'h'}`}
                    onTransitionEnd={handleTransitionEnd}
                >
                    <Spinner animation="grow" variant="primary" />
                    <span className="sr-only">Loading...</span>
                </div>
            )}
        </>
    );
}
