import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    FormEvent,
} from 'react';

import {Col, Form, Button, ListGroup} from 'react-bootstrap';
import {GoPlus} from 'react-icons/go';

import {
    getIngredients as fetchIngredients,
    TValidGetQueries,
    IIngredientsAxiosResponse,
} from '../helpers/api';

import FilterForm from './FilterForm';
import IngredientForm, {IIngredientProps} from './IngredientForm';
import {generateId, processErrors} from '../helpers/utils';
import {MessageModal} from './MessageModal';
import {AxiosError} from 'axios';

export default function IngredientsList(): ReactElement {
    const [ingredients, setIngredients] = useState(
        [] as Array<IIngredientProps>
    );

    const [ingredientsCount, setIngredientsCount] = useState(0);
    const [filter, setFilter] = useState('');
    const [errors, setErrors] = useState([] as JSX.Element[]);
    const [showErrors, setShowErrors] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const removeIngredient = (removedIngredientId: string): void => {
        setIngredients((prevState) =>
            prevState.filter((prev) => prev._id !== removedIngredientId));

        setIngredientsCount((prevState) => prevState - 1);
    };

    const listifyIngredients = (
        ingredientsArray: Array<IIngredientProps>
    ): JSX.Element => {
        const ings = ingredientsArray.map((ing, idx) => (
            <ListGroup.Item key={ing._id}>
                <IngredientForm
                    _id={ing._id}
                    name={ing.name}
                    cost_per_gram={ing.cost_per_gram}
                    onSave={(isNew, savedIngredient): void => {
                        setButtonDisabled(false);

                        if(isNew && savedIngredient) {
                            setIngredients((prevState) => {
                                const newState = [...prevState];

                                newState[idx] = savedIngredient;

                                return newState;
                            });
                        }
                    }}
                    onDelete={(removedIngredientId): void => {
                        if(removedIngredientId) {
                            removeIngredient(removedIngredientId);
                        }

                        setButtonDisabled(false);
                    }}
                />
            </ListGroup.Item>
        ));

        return <ListGroup className="input-list-group">{ings}</ListGroup>;
    };

    useEffect(() => {
        setIsFetching(true);

        fetchIngredients({
            onSuccess: ({
                count,
                results,
            }: IIngredientsAxiosResponse['data']): void => {
                setIngredientsCount(count);
                setIngredients(results);
            },
            onError: (err: AxiosError) => {
                setErrors(processErrors(err));
                setShowErrors(true);
            },
            onFinally: () => setIsFetching(false),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addNewIngredient = (e: FormEvent): void => {
        e.preventDefault();

        setIngredients([{_id: generateId(), name: ''}, ...ingredients]);

        setButtonDisabled(true);
    };

    const getMoreIngredients = (e: FormEvent): void => {
        e.preventDefault();

        const queries: TValidGetQueries = {};

        if(filter) queries.filter = filter;

        if(ingredients[ingredients.length - 1]) {
            queries.last_result = ingredients[ingredients.length - 1]._id;
        }

        setIsFetching(true);
        setButtonDisabled(true);

        fetchIngredients(
            {
                onSuccess: ({
                    results,
                }: IIngredientsAxiosResponse['data']): void => {
                    setIngredients([...ingredients, ...results]);
                },
                onError: (err: AxiosError) => {
                    setErrors(processErrors(err));
                    setShowErrors(true);
                },
                onFinally: () => {
                    setIsFetching(false);
                    setButtonDisabled(false);
                },
            },
            queries
        );
    };

    const filterFormOnSubmit = (filterSetButtonDisabled: Function): void => {
        setIsFetching(true);
        filterSetButtonDisabled(true);

        fetchIngredients(
            {
                onSuccess: ({
                    count,
                    results,
                }: IIngredientsAxiosResponse['data']): void => {
                    setIngredientsCount(count);
                    setIngredients(results);
                },
                onError: (err: AxiosError) => {
                    setErrors(processErrors(err));
                    setShowErrors(true);
                },
                onFinally() {
                    setIsFetching(false);
                    filterSetButtonDisabled(false);
                    setButtonDisabled(false);
                },
            },
            {filter}
        );
    };

    const filterFormOnValueChange = (value: string): void => setFilter(value);

    return (
        <>
            {
                <MessageModal
                    type="error"
                    show={showErrors}
                    onHide={(): void => setShowErrors(false)}
                    onExited={(): void => setErrors([])}
                    body={errors}
                />
            }
            <Form.Row className="mb-4">
                <Col xs="auto">
                    <Button
                        onClick={addNewIngredient}
                        disabled={buttonDisabled}
                    >
                        <GoPlus className="d-sm-none" />
                        <span className="d-none d-sm-inline-block">
                            Add New Ingredient
                        </span>
                    </Button>
                </Col>
                <Col>
                    <FilterForm
                        buttonText="Search"
                        placeholder="e.g. Sugar"
                        onSubmit={filterFormOnSubmit}
                        onValueChange={filterFormOnValueChange}
                    />
                </Col>
            </Form.Row>
            {ingredients.length ? (
                listifyIngredients(ingredients)
            ) : (
                <p>{isFetching ? 'Loading...' : 'No results.'}</p>
            )}
            {ingredientsCount > ingredients.length && (
                <div className="mt-4">
                    <Button
                        block
                        onClick={getMoreIngredients}
                        disabled={buttonDisabled}
                    >
                        {isFetching ? 'Fetching' : 'Load More Ingredients'}
                    </Button>
                </div>
            )}
        </>
    );
}
