import React, {ReactElement} from 'react';
import {Container} from 'react-bootstrap';

export default function Footer(): ReactElement {
    return (
        <footer className="app-footer bg-light">
            <Container className="pt-4 pb-4">
                <div className="text-center">
                    Copyright &copy; {new Date().getFullYear()} Chicha. All
                    rights reserved.
                </div>
            </Container>
        </footer>
    );
}
