import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    FormEvent,
} from 'react';

import {Col, Form, Button} from 'react-bootstrap';

type TProps = {
    buttonText?: string;
    placeholder?: string;
    onSubmit?: Function;
    onValueChange?: Function;
};

export default function FilterForm(props: TProps): ReactElement {
    const [fields, setFields] = useState({
        filter: '',
    });

    const [lastFilter, setLastFilter] = useState('');
    const [filterEqLastFilter, setFilterEqLastFilter] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const handleValueChange = (e: FormEvent): void => {
        const t = e.target as HTMLInputElement;
        const value = t.type === 'checkbox' ? t.checked : t.value;
        const updatedFields = {...fields, [t.name]: value};

        setFields(updatedFields);
        setFilterEqLastFilter(updatedFields.filter === lastFilter);

        if(props.onValueChange) props.onValueChange(value);
    };

    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();

        setLastFilter(fields.filter);
        setFilterEqLastFilter(true);

        if(props.onSubmit) props.onSubmit(setButtonDisabled);
    };

    return (
        <Form className="filter-form" onSubmit={handleSubmit} noValidate>
            <Form.Row>
                <Col>
                    <Form.Label htmlFor="filter" className="sr-only">
                        Filter
                    </Form.Label>
                    <Form.Control
                        type="text"
                        id="filter"
                        name="filter"
                        placeholder={props.placeholder || 'Filter'}
                        value={fields.filter}
                        onChange={handleValueChange}
                    />
                </Col>
                <Col xs="auto">
                    <Button
                        type="submit"
                        disabled={buttonDisabled || filterEqLastFilter}
                    >
                        {props.buttonText || 'Filter'}
                    </Button>
                </Col>
            </Form.Row>
        </Form>
    );
}
