import React, {ReactElement} from 'react';

import PageHeader from '../components/PageHeader';
import RecipesList from '../components/RecipesList';
import {Helmet} from 'react-helmet';

export default function Recipes(): ReactElement {
    return (
        <>
            <Helmet>
                <title>Recipes</title>
            </Helmet>
            <PageHeader>Recipes</PageHeader>
            <RecipesList />
        </>
    );
}
