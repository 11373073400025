import React, {ReactElement} from 'react';

import PageHeader from '../components/PageHeader';
import {Helmet} from 'react-helmet';

export default function NotFound(): ReactElement {
    return (
        <>
            <Helmet>
                <title>404</title>
            </Helmet>
            <PageHeader>Nothing to see here</PageHeader>
            <p>This is the end of the internet. You should not be here.</p>
        </>
    );
}
