import React, {ReactElement} from 'react';

import PageHeader from '../components/PageHeader';
import RecipeForm from '../components/RecipeForm';
import {Helmet} from 'react-helmet';

export default function RecipeAdd(): ReactElement {
    return (
        <>
            <Helmet>
                <title>New Recipe</title>
            </Helmet>
            <PageHeader>New Recipe</PageHeader>
            <RecipeForm />
        </>
    );
}
