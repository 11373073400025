import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    FormEvent,
} from 'react';

import {AxiosError} from 'axios';
import {Form, Col, Button, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import {GoBeaker, GoPencil} from 'react-icons/go';
import {FaRegTrashAlt} from 'react-icons/fa';
import {FiCopy} from 'react-icons/fi';
import {Link} from 'react-router-dom';

import {deleteRecipe} from '../helpers/api';
import {MessageModal} from './MessageModal';
import {processErrors} from '../helpers/utils';

export interface IRecipeListItemOnDeleteFunction {
    (deletedRecipeId?: IRecipeListItemProps['_id']): unknown;
}

export interface IRecipeListItemProps {
    _id: string;
    name: string;
    onDelete?: IRecipeListItemOnDeleteFunction;
}

export default function RecipeListItem(
    props: IRecipeListItemProps
): ReactElement {
    const [errors, setErrors] = useState([] as JSX.Element[]);
    const [showErrors, setShowErrors] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const trigger = useRef<HTMLButtonElement>(null);

    const handleDelete = (e: FormEvent): void => {
        e.preventDefault();

        setButtonDisabled(true);

        if(!props._id.startsWith('___')) {
            deleteRecipe(props._id, {
                onError: (err: AxiosError) => {
                    setErrors(processErrors(err));
                    setShowErrors(true);
                },
                onSuccess: () => {
                    if(props.onDelete) props.onDelete(props._id);
                },
                onFinally: () => {
                    if(isMounted.current) setButtonDisabled(false);
                },
            });
        } else if(props.onDelete) props.onDelete(props._id);
    };

    const delPopover = (id: string): ReactElement => (
        <Popover id={`delete-po-${id}`}>
            <Popover.Title>Are you sure?</Popover.Title>
            <Popover.Content>
                <div className="mb-2">This cannot be undone.</div>
                <Row className="tight-gutters fmb mb-1">
                    <Col>
                        <Button
                            block
                            onClick={(e: FormEvent): void => {
                                if(trigger && trigger.current) {
                                    trigger.current.click();
                                }

                                handleDelete(e);
                            }}
                            size="sm"
                        >
                            Yes
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            block
                            onClick={(): void => {
                                if(trigger && trigger.current) {
                                    trigger.current.click();
                                }
                            }}
                            size="sm"
                        >
                            No
                        </Button>
                    </Col>
                </Row>
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            {
                <MessageModal
                    type="error"
                    show={showErrors}
                    onHide={(): void => setShowErrors(false)}
                    onExited={(): void => setErrors([])}
                    body={errors}
                />
            }
            <Form onSubmit={(): boolean => false} noValidate>
                <Form.Row>
                    <Col xs={12} md className="mb-frg mb-md-0">
                        <Form.Label htmlFor="name" className="sr-only">
                            Filter
                        </Form.Label>
                        <Form.Control
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={props.name}
                            disabled={true}
                        />
                    </Col>
                    <Col xs md="auto">
                        <Button
                            as={Link}
                            to={`/recipes/${props._id}`}
                            variant="warning"
                            block
                            disabled={buttonDisabled}
                        >
                            <GoBeaker />
                            <span className="sr-only">Science It</span>
                        </Button>
                    </Col>
                    <Col xs md="auto">
                        <Button
                            as={Link}
                            to={`/recipes/${props._id}?edit=true`}
                            variant="success"
                            block
                            disabled={buttonDisabled}
                        >
                            <GoPencil />
                            <span className="sr-only">Edit</span>
                        </Button>
                    </Col>
                    <Col xs md="auto">
                        <Button block disabled={buttonDisabled}>
                            <FiCopy />
                            <span className="sr-only">Duplicate</span>
                        </Button>
                    </Col>
                    <Col xs md="auto">
                        <OverlayTrigger
                            trigger="click"
                            key={`delete-ot-${props._id}`}
                            placement="left"
                            rootClose={true}
                            overlay={delPopover(props._id)}
                        >
                            <Button
                                block
                                ref={trigger}
                                variant="danger"
                                disabled={buttonDisabled}
                            >
                                <FaRegTrashAlt />
                                <span className="sr-only">Delete</span>
                            </Button>
                        </OverlayTrigger>
                    </Col>
                </Form.Row>
            </Form>
        </>
    );
}
