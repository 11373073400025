import React, {useEffect, useRef, useState, ReactElement} from 'react';

import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import {
    getIngredients as fetchIngredients,
    IIngredientsAxiosResponse,
} from '../helpers/api';

import {IIngredientProps} from './IngredientForm';
import {TStuckOnAny, generateId} from '../helpers/utils';

export interface IIngredientSelectProps {
    id?: string;
    placeholder?: string;
    options?: IIngredientProps[];
    defaultInputValue?: string;
    onChange?: (selected: Array<TStuckOnAny>) => void;
    onInputChange?: (value: string) => void;
}

export interface IIngredientSelectExtended extends IIngredientProps {
    customOption?: boolean;
}

export const ingredientSeed = (name = ''): IIngredientProps => ({
    _id: generateId(),
    name,
});

export default function IngredientSelect(
    props: IIngredientSelectProps
): ReactElement {
    const [isLoading, setIsLoading] = useState(false);
    const [allowNew, setAllowNew] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);

    const [options, setOptions] = useState(
        props.options || ([] as IIngredientProps[])
    );

    const ref = useRef();
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const handleSearch = (filter: string): void => {
        setAllowNew(false);
        setIsLoading(true);

        fetchIngredients(
            {
                onSuccess: ({
                    results,
                }: IIngredientsAxiosResponse['data']): void => {
                    setOptions(results);
                },
                onFinally() {
                    setAllowNew(true);
                    setIsLoading(false);
                },
            },
            {filter}
        );
    };

    const handleChange = (selected: IIngredientSelectExtended[]): void => {
        if(selected.length) {
            setAllowNew(false);
            setHasSelected(true);
        } else {
            setHasSelected(false);
        }

        if(props.onChange) props.onChange(selected);
    };

    const handleInputChange = (value: string): void => {
        if(props.onInputChange) props.onInputChange(value);
    };

    const handleBlur = (e: Event): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore-next-line
        if(!hasSelected) ref.current.clear();
    };

    return (
        <AsyncTypeahead
            id={props.id || 'none'}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore-next-line
            ref={ref}
            filterBy={(option, _props): boolean => {
                let words = '';

                _props.text
                    .replace(/[^\w\s]/gi, ' ')
                    .split(' ')
                    .forEach((val): void => {
                        words += `(?=.*${val})`;
                    });

                return Boolean(
                    option.name
                        && option.name
                            .toLowerCase()
                            .match(new RegExp(`^${words}.*`, 'ig'))
                );
            }}
            labelKey="name"
            isLoading={isLoading}
            delay={300} // TODO: should there be a delay?
            minLength={2}
            emptyLabel="Searching..."
            promptText="Searching..."
            searchText="Searching..."
            onBlur={handleBlur}
            onSearch={handleSearch}
            onChange={handleChange}
            onInputChange={handleInputChange}
            allowNew={allowNew}
            newSelectionPrefix="Add: "
            options={options}
            selectHintOnEnter={true}
            defaultInputValue={props.defaultInputValue}
            placeholder={props.placeholder || 'Start typing...'}
            renderMenuItemChildren={(
                option: IIngredientProps
            ): ReactElement => <div>{option.name}</div>}
        />
    );
}
