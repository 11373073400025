import React from 'react';
import {AxiosError} from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TStuckOnAny = any;

export interface ILocationState {
    registered?: boolean;
}

export const errorLabels = {
    generic: 'Something went wrong. Please try again.',
};

export const generateId = ({
    prefix = '___',
    keysToCompare = [],
}: {
    prefix?: string;
    keysToCompare?: string[];
} = {}): string => {
    const output = `${prefix}${Math.random().toString(36).substr(2, 10)}`;

    if(keysToCompare.includes(output)) generateId({prefix, keysToCompare});

    return output;
};

export const processErrors = (err: AxiosError): JSX.Element[] => {
    const errs = [];

    if(err.code === 'ECONNABORTED') {
        errs.push(<p key="timedout">Request timed out. Please try again.</p>);
    } else if(err.response && err.response.data.errors) {
        for(let i = 0; i < err.response.data.errors.length; i++) {
            const {message} = err.response.data.errors[i];

            errs.push(<p key={i}>{message}</p>);
        }
    } else if(err.response && err.response.status === 401) {
        errs.push(<p key={err.response.status}>Wrong credentials.</p>);
    } else if(err.response && err.response.data.message) {
        errs.push(<p key={err.response.status}>{err.response.data.message}</p>);
    } else {
        errs.push(<p key="generic">{errorLabels.generic}</p>);
    }

    return errs;
};
