import React, {
    useEffect,
    useRef,
    useState,
    ReactElement,
    FormEvent,
} from 'react';

import {AxiosError} from 'axios';
import {Form, Col, Button, ListGroup} from 'react-bootstrap';
import {GoPlus} from 'react-icons/go';
import {Link} from 'react-router-dom';

import {
    getRecipes as fetchRecipes,
    TValidGetQueries,
    IRecipesAxiosResponse,
} from '../helpers/api';

import FilterForm from './FilterForm';
import {IRecipeProps} from './RecipeForm';
import RecipeListItem from './RecipeListItem';
import {MessageModal} from './MessageModal';
import {processErrors} from '../helpers/utils';

export default function Recipes(): ReactElement {
    const [recipes, setRecipes] = useState([] as Array<IRecipeProps>);
    const [recipesCount, setRecipesCount] = useState(0);
    const [filter, setFilter] = useState('');
    const [errors, setErrors] = useState([] as JSX.Element[]);
    const [showErrors, setShowErrors] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    const removeRecipe = (removedRecipeId: string): void => {
        setRecipes((prevState) =>
            prevState.filter((prev) => prev._id !== removedRecipeId));

        setRecipesCount((prevState) => prevState - 1);
    };

    const listifyRecipes = (recipesArray: Array<IRecipeProps>): JSX.Element => {
        const recs = recipesArray.map((rec) => (
            <ListGroup.Item key={rec._id}>
                <RecipeListItem
                    _id={rec._id || ''}
                    name={rec.name || ''}
                    onDelete={(removedRecipeId): void => {
                        if(removedRecipeId) {
                            removeRecipe(removedRecipeId);
                        }

                        setButtonDisabled(false);
                    }}
                />
            </ListGroup.Item>
        ));

        return <ListGroup className="input-list-group">{recs}</ListGroup>;
    };

    useEffect(() => {
        setIsFetching(true);

        fetchRecipes({
            onSuccess: ({
                count,
                results,
            }: IRecipesAxiosResponse['data']): void => {
                setRecipesCount(count);
                setRecipes(results);
            },
            onError: (err: AxiosError) => {
                setErrors(processErrors(err));
                setShowErrors(true);
            },
            onFinally: () => setIsFetching(false),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMoreRecipes = (e: FormEvent): void => {
        e.preventDefault();

        const queries: TValidGetQueries = {};

        if(filter) queries.filter = filter;

        if(recipes[recipes.length - 1]) {
            queries.last_result = recipes[recipes.length - 1]._id;
        }

        setIsFetching(true);
        setButtonDisabled(true);

        fetchRecipes(
            {
                onSuccess: ({results}: IRecipesAxiosResponse['data']): void => {
                    setRecipes([...recipes, ...results]);
                },
                onError: (err) => {
                    setErrors(processErrors(err));
                    setShowErrors(true);
                },
                onFinally: () => {
                    setIsFetching(false);
                    setButtonDisabled(false);
                },
            },
            queries
        );
    };

    const filterFormOnSubmit = (filterSetButtonDisabled: Function): void => {
        setIsFetching(true);
        filterSetButtonDisabled(true);

        fetchRecipes(
            {
                onSuccess: ({
                    count,
                    results,
                }: IRecipesAxiosResponse['data']): void => {
                    setRecipesCount(count);
                    setRecipes(results);
                },
                onError: (err) => {
                    setErrors(processErrors(err));
                    setShowErrors(true);
                },
                onFinally() {
                    setIsFetching(false);
                    filterSetButtonDisabled(false);
                    setButtonDisabled(false);
                },
            },
            {filter}
        );
    };

    const filterFormOnValueChange = (value: string): void => setFilter(value);

    return (
        <>
            {
                <MessageModal
                    type="error"
                    show={showErrors}
                    onHide={(): void => setShowErrors(false)}
                    onExited={(): void => setErrors([])}
                    body={errors}
                />
            }
            <Form.Row className="mb-4">
                <Col xs="auto">
                    <Button as={Link} to="/recipes/add">
                        <GoPlus className="d-sm-none" />
                        <span className="d-none d-sm-inline-block">
                            Add New Recipe
                        </span>
                    </Button>
                </Col>
                <Col>
                    <FilterForm
                        buttonText="Search"
                        placeholder="e.g. Adobo"
                        onSubmit={filterFormOnSubmit}
                        onValueChange={filterFormOnValueChange}
                    />
                </Col>
            </Form.Row>
            {recipes.length ? (
                listifyRecipes(recipes)
            ) : (
                <p>{isFetching ? 'Loading...' : 'No results.'}</p>
            )}
            {recipesCount > recipes.length && (
                <div className="mt-4">
                    <Button
                        block
                        onClick={getMoreRecipes}
                        disabled={buttonDisabled}
                    >
                        {isFetching ? 'Fetching' : 'Load More Recipes'}
                    </Button>
                </div>
            )}
        </>
    );
}
