import {
    SortableElement as element,
    SortableContainer as container,
    SortableHandle as handle,
} from 'react-sortable-hoc';

import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import React, {ReactElement, Dispatch, FormEvent} from 'react';
import {ListGroup, Form, Col, Button, InputGroup, Row} from 'react-bootstrap';
import {GoX, GoThreeBars, GoPlus} from 'react-icons/go';
import {AxiosResponse} from 'axios';

import {IRecipeIngredientProps} from './RecipeForm';
import IngredientSelect, {ingredientSeed} from './IngredientSelect';
import {addIngredient} from '../helpers/api';

export interface ISortableIngredientsProps {
    items: IRecipeIngredientProps[];
    setItems: Dispatch<React.SetStateAction<IRecipeIngredientProps[]>>;
    deleteButtonDisabled: boolean;
    readonly?: boolean;
}

const SortableItem = element(({children}: {children: ReactElement}) => (
    <>{children}</>
));

const SortableList = container(({children}: {children: ReactElement}) => (
    <>{children}</>
));

const DragHandle = handle(() => (
    <Button as="div" variant="dark">
        <GoThreeBars />
        <span className="sr-only">Drag to Sort</span>
    </Button>
));

export default function SortableIngredients(
    props: ISortableIngredientsProps
): ReactElement {
    const addItem = (): void => {
        if(props.readonly) return;

        props.setItems([
            {ingredient: ingredientSeed(), percentage: '0'},
            ...props.items,
        ]);
    };

    const ingredientFormTemplate = (idx: number): JSX.Element => (
        <ListGroup.Item>
            <Form.Row>
                <Col className="mb-frg mb-md-0" xs="12" md>
                    <Form.Label
                        srOnly
                        htmlFor={`ingredients[${idx}]ingredient`}
                    >
                        Ingredient {idx + 1} Name
                    </Form.Label>
                    <IngredientSelect
                        id={`ingredients[${idx}]ingredient`}
                        options={[props.items[idx].ingredient]}
                        defaultInputValue={props.items[idx].ingredient.name}
                        placeholder="Select ingredient..."
                        onChange={(selected): void => {
                            if(selected[0] && selected[0].customOption) {
                                addIngredient(selected[0], {
                                    onSuccess: (
                                        data: AxiosResponse['data']
                                    ) => {
                                        const newState = [...props.items];

                                        newState[idx].ingredient = data.details;

                                        props.setItems(newState);
                                    },
                                });
                            } else {
                                const newState = [...props.items];

                                newState[idx].ingredient = selected[0]
                                    ? selected[0]
                                    : ingredientSeed();

                                props.setItems(newState);
                            }
                        }}
                    />
                </Col>
                <Col xs md="4" lg="3">
                    <Form.Label
                        srOnly
                        htmlFor={`ingredients[${idx}]percentage`}
                    >
                        Ingredient {idx + 1} Percentage
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            min={0}
                            id={`ingredients[${idx}]percentage`}
                            name={`ingredients[${idx}]percentage`}
                            placeholder="Percentage"
                            aria-describedby="_percentage"
                            value={props.items[idx].percentage}
                            onChange={(e: FormEvent): void => {
                                const t = e.target as HTMLInputElement;
                                const newState = [...props.items];

                                newState[idx].percentage = t.value;

                                props.setItems(newState);
                            }}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="_percentage">
                                %
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                {!props.readonly && (
                    <>
                        <Col xs="auto">
                            <DragHandle />
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant="danger"
                                onClick={(e: FormEvent): void => {
                                    e.preventDefault();

                                    props.setItems((prevState) =>
                                        prevState.filter(
                                            (_prev, i) => i !== idx
                                        ));
                                }}
                                disabled={props.deleteButtonDisabled}
                            >
                                <GoX />
                                <span className="sr-only">Delete</span>
                            </Button>
                        </Col>
                    </>
                )}
            </Form.Row>
        </ListGroup.Item>
    );

    ingredientFormTemplate.propTypes = {
        items: PropTypes.arrayOf(PropTypes.any),
        setItems: PropTypes.func,
        deleteButtonDisabled: PropTypes.bool,
        readonly: PropTypes.bool,
    };

    return (
        <SortableList
            lockAxis="y"
            useDragHandle={true}
            onSortEnd={({
                oldIndex,
                newIndex,
            }: {
                oldIndex: number;
                newIndex: number;
            }): void => {
                const newItems = arrayMove(props.items, oldIndex, newIndex);

                if(oldIndex !== newIndex) props.setItems(newItems);
            }}
        >
            <ListGroup className="input-list-group mb-4">
                <ListGroup.Item
                    className={`iconed${props.readonly ? '' : ' c-ptr'}`}
                    onClick={addItem}
                    active
                >
                    <Row>
                        <Col>Ingredients</Col>
                        {!props.readonly && (
                            <Col xs="auto">
                                <GoPlus />
                            </Col>
                        )}
                    </Row>
                </ListGroup.Item>
                {props.items.map((item, idx) => (
                    <SortableItem key={item.ingredient._id} index={idx}>
                        {ingredientFormTemplate(idx)}
                    </SortableItem>
                ))}
            </ListGroup>
        </SortableList>
    );
}
