import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {RouteProps} from 'react-router';
import {useLocation, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import PageHeader from '../components/PageHeader';
import RecipeForm, {IRecipeProps} from '../components/RecipeForm';
import {getRecipe, IRecipeAxiosResponse} from '../helpers/api';
import {AxiosError} from 'axios';
import {processErrors} from '../helpers/utils';
import {MessageModal} from '../components/MessageModal';
import RecipeScienced from '../components/RecipeScienced';

const useQuery = (): URLSearchParams =>
    new URLSearchParams(useLocation().search);

export default function RecipeView(props: RouteProps): ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locState = (props.location && props.location.state) || ({} as any);

    const [isFetching, setIsFetching] = useState(true);
    const [recipe, setRecipe] = useState({} as IRecipeProps);
    const [showRecipe, setShowRecipe] = useState(false);
    const [errors, setErrors] = useState([] as JSX.Element[]);
    const [showErrors, setShowErrors] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const isMounted = useRef(false);

    const {id} = useParams<Record<string, any>>();
    const isEdit = useQuery().get('edit');

    useEffect(() => {
        isMounted.current = true;

        return (): void => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if(locState.savedRecipe) {
            setRecipe(locState.savedRecipe);
            setShowRecipe(true);
        } else {
            setIsFetching(true);

            getRecipe(id, {
                onSuccess: (result: IRecipeAxiosResponse['data']): void => {
                    setRecipe(result);
                    setShowRecipe(true);
                },
                onError: (err: AxiosError) => {
                    if(
                        err.response
                        && (err.response.status === 404
                            || err.response.status === 400)
                    ) {
                        setNotFound(true);
                    } else {
                        setErrors(processErrors(err));
                        setShowErrors(true);
                    }
                },
                onFinally: () => setIsFetching(false),
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                <MessageModal
                    type="error"
                    show={showErrors}
                    onHide={(): void => setShowErrors(false)}
                    onExited={(): void => setErrors([])}
                    body={errors}
                />
            }
            {showRecipe && (
                <>
                    <Helmet>
                        <title>{recipe.name}</title>
                    </Helmet>
                    <PageHeader>{recipe.name}</PageHeader>
                    {isEdit ? (
                        <RecipeForm
                            {...recipe}
                            isNew={Boolean(locState.isNew)}
                        />
                    ) : (
                        <RecipeScienced recipe={recipe} />
                    )}
                </>
            )}
            {!showRecipe && isFetching && (
                <>
                    <Helmet>
                        <title>Recipe</title>
                    </Helmet>
                    <PageHeader>Recipe</PageHeader>
                    <p>Loading...</p>
                </>
            )}
            {!showRecipe && !isFetching && notFound && (
                <>
                    <Helmet>
                        <title>Recipe Not Found</title>
                    </Helmet>
                    <PageHeader>Recipe Not Found</PageHeader>
                    <p>It may have been deleted.</p>
                </>
            )}
        </>
    );
}
